import { Slide } from "react-toastify";

export const toastifyOptions = {
	position: "top-center",
	autoClose: 3000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: 0,
	transition: Slide,
	toastId: "ts",
	limit: 1,
};
