import React, { useEffect } from "react";
import Otp from "../src/pages/Otp";
import FAQ from "../src/pages/Faq";
import Home from "../src/pages/Home/";
import UploadID from "./pages/UploadID";
import Signup from "../src/pages/Signup";
import Success from "../src/pages/Success";
import TermsAndConditions from "./pages/Tnc";
import PrivacyPolicy from "../src/pages/PPolicy";
import CompleteProfile from "../src/pages/CompleteProfile";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

export const App = () => {
	let show = false;

	useEffect(() => {
		window.location.replace("https://mybezoapp.com");
	}, []);
	return (
		show && (
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/signup" component={Signup} />
					<Route path="/otp" component={Otp} />
					<Route
						exact
						path="/complete-profile"
						component={CompleteProfile}
					/>
					<Route path="/success" component={Success} />
					<Route path="/privacy-policy" component={PrivacyPolicy} />
					<Route path="/terms" component={TermsAndConditions} />

					<Route path="/faq" component={FAQ} />
					<Route path="/complete-profile/id" component={UploadID} />
					<Redirect to="/" />
				</Switch>
			</BrowserRouter>
		)
	);
};
