import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

import useScroll from "./../utils/useScroll";
import FadeIn from "react-fade-in";

const Faq = () => {
	useScroll();

	return (
		<FadeIn>
			<Navigation />

			<section className=" pagebazot">
				<div className="container ">
					<div className="row centerit">
						<div className="col-12 col-md-6 px-5 pptop ">
							<h1 className="bezosusutw seconfcol">
								Frequently Asked Questions
							</h1>

							<p className="mt-4 fitaa">
								Got a question? We are here to help! If you
								don’t see your question here, drop us a line on
								our contact at the bottom of this page.
							</p>
						</div>

						<div className="col-12 col-md-6 my-5 text-center">
							<img
								src="img/faq.svg"
								className="img-fluid w-75"
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="space">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-7  mx-auto">
							<div className="mb-5">
								<h4 className="my-4 seconfcol">
									{" "}
									<strong>General Questions </strong>{" "}
								</h4>
								<div
									className="accordion"
									id="accordionGeneralquestions"
								>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingOne"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#GeneralquestionsOne"
												aria-expanded="false"
												aria-controls="GeneralquestionsOne"
											>
												Where will my money be stored?
											</button>
										</h2>
										<div
											id="GeneralquestionsOne"
											className="accordion-collapse collapse"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionGeneralquestions"
										>
											<div className="accordion-body">
												Your savings is stored with our
												custodian bank. This is to
												ensure security and growth of
												your savings.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingTwo"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#GeneralquestionsTwo"
												aria-expanded="false"
												aria-controls="GeneralquestionsTwo"
											>
												Is BezoSusu secure?
											</button>
										</h2>
										<div
											id="GeneralquestionsTwo"
											className="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionGeneralquestions"
										>
											<div className="accordion-body">
												Yes, it is very secure. Using
												your BezoPIN, you can confirm
												your savings goals and all
												transactions. Always keep your
												BezoPIN safe.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingThree"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#GeneralquestionsThree"
												aria-expanded="false"
												aria-controls="GeneralquestionsThree"
											>
												Is BezoSusu regulated?
											</button>
										</h2>
										<div
											id="GeneralquestionsThree"
											className="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionGeneralquestions"
										>
											<div className="accordion-body">
												Yes, BezoSusu is a subsidiary of
												BezoMoney which is regulated by
												the Bank of Ghana Fintech
												Office.
											</div>
										</div>
									</div>

									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingFour"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#GeneralquestionsFour"
												aria-expanded="false"
												aria-controls="GeneralquestionsFour"
											>
												Can I use any Mobile Money
												Account?
											</button>
										</h2>
										<div
											id="GeneralquestionsFour"
											className="accordion-collapse collapse"
											aria-labelledby="headingFour"
											data-bs-parent="#accordionGeneralquestions"
										>
											<div className="accordion-body">
												Yes! BezoSusu works with any
												Mobile Money account. Enter your
												Mobile Money account number
												during the registration process
												to have it connected to your
												BezoSusu Account.
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="mb-5">
								<h4 className="my-4 seconfcol">
									{" "}
									<strong>Financial </strong>{" "}
								</h4>
								<div
									className="accordion"
									id="accordionFinancial"
								>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingOne"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#FinancialOne"
												aria-expanded="false"
												aria-controls="FinancialOne"
											>
												Will I get an interest on my
												savings?
											</button>
										</h2>
										<div
											id="FinancialOne"
											className="accordion-collapse collapse"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionFinancial"
										>
											<div className="accordion-body">
												Yes, our custodian bank will
												provide 9% annual interest rate
												on your deposits which you can
												choose to receive.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingTwo"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#FinancialTwo"
												aria-expanded="false"
												aria-controls="FinancialTwo"
											>
												What is the minimum amount
												needed to open a BezoSusu
												account?
											</button>
										</h2>
										<div
											id="FinancialTwo"
											className="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionFinancial"
										>
											<div className="accordion-body">
												You would need to make an
												initial minimum deposit of GHC5
												which will be deducted from your
												mobile money wallet after you
												create your account.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingThree"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#FinancialThree"
												aria-expanded="false"
												aria-controls="FinancialThree"
											>
												What are some benefits I can
												enjoy from saving with BezoSusu?
											</button>
										</h2>
										<div
											id="FinancialThree"
											className="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionFinancial"
										>
											<div className="accordion-body">
												You can build up your BezoScore
												which will allow you to enjoy
												amazing financial benefits to
												address your needs when they are
												launched.
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="mb-5">
								<h4 className="my-4 seconfcol">
									{" "}
									<strong>Technological</strong>{" "}
								</h4>
								<div
									className="accordion"
									id="accordionTechnological"
								>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingOne"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#TechnologicalOne"
												aria-expanded="false"
												aria-controls="TechnologicalOne"
											>
												How do I keep track of my
												savings goal?
											</button>
										</h2>
										<div
											id="TechnologicalOne"
											className="accordion-collapse collapse"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionTechnological"
										>
											<div className="accordion-body">
												We will send you SMS reminders
												to save and also you can visit
												option 3 on the USSD menu to
												check your account balance. Just
												Dial *920*75#.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingTwo"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#TechnologicalTwo"
												aria-expanded="false"
												aria-controls="TechnologicalTwo"
											>
												Can I lock my savings?
											</button>
										</h2>
										<div
											id="TechnologicalTwo"
											className="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionTechnological"
										>
											<div className="accordion-body">
												You can select the option to
												lock your savings so as to
												prevent yourself from
												withdrawing your savings
												pre-maturely.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingThree"
										>
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#TechnologicalThree"
												aria-expanded="false"
												aria-controls="TechnologicalThree"
											>
												Can I make deposits
												automatically?
											</button>
										</h2>
										<div
											id="TechnologicalThree"
											className="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionTechnological"
										>
											<div className="accordion-body">
												Yes. When creating a savings
												goal, you can select automatic
												deposits which would allow funds
												to move automatically from your
												Mobile Money wallet to your
												BezoSusu Account.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</FadeIn>
	);
};

export default Faq;
