import React from "react";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
	render() {
		const date = new Date();
		const year = date.getFullYear()
		return (
			<section className="fot mt-5 ">
				<div className="container pt-5">
					<div className="row  ">
						<div className="col-12 col-md-3 text-center  mb-5">
							<img
								src="img/bezosusulogo.svg"
								width="150px"
								alt=""
							/>
							<ul className="list-inline mt-3">
								<li className="list-inline-item px-1">
									<a
										href="https://web.facebook.com/BezoSusu-100830051824973/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="img/facebook.svg"
											alt=""
											width="10"
										/>
									</a>
								</li>

								<li className="list-inline-item px-1">
									<a
										href="https://twitter.com/bezosusu"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="img/twitter.svg"
											alt=""
											width="20"
										/>
									</a>
								</li>

								<li className="list-inline-item px-1">
									<a
										href="https://www.instagram.com/bezosusu/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="img/instagram.svg"
											alt=""
											width="20"
										/>
									</a>
								</li>

								<li className="list-inline-item px-1">
									<a
										href="https://www.linkedin.com/company/bezosusu/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="img/linkedin.svg"
											alt=""
											width="20"
										/>
									</a>
								</li>

								<li className="list-inline-item px-1">
									<a
										href="http://wa.me/233205120095"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="img/whatsapp.svg"
											alt=""
											width="20"
										/>
									</a>
								</li>
							</ul>
						</div>

						<div className="col-6 col-md-3  mb-5 ">
							<p className="wc">
								<strong>Explore</strong>
							</p>
							<ul className="list-unstyled cocul">
								<li className="py-1 ">
									{" "}
									<a
										href="https://www.bezomoney.com"
										target="_blank"
										rel="noreferrer"
										className="balcksmall"
									>
										Bezomoney
									</a>
								</li>
								<li className="py-1 ">
									{" "}
									<a
										href="https://app.bezosusu.com/auth/register"
										className="balcksmall"
									>
										Sign Up
									</a>
								</li>
							</ul>
						</div>

						<div className="col-6 col-md-3  mb-5">
							<p className="wc">
								<strong>Information</strong>
							</p>
							<ul className="list-unstyled cocul">
								<li className="py-1 ">
									{" "}
									<Link to="/faq" className="balcksmall">
										F.A.Q
									</Link>
								</li>
								<li className="py-1 ">
									{" "}
									<Link
										to="/privacy-policy"
										className="balcksmall"
									>
										Privacy Policy
									</Link>
								</li>
								<li className="py-1 ">
									{" "}
									<Link to="/terms" className="balcksmall">
										Terms and Conditions
									</Link>
								</li>
							</ul>
						</div>

						<div className="col-12   text-md-left col-md-3  mb-4">
							<p className="wc">
								<strong>Contact Us</strong>
							</p>
							<ul className=" list-unstyled  cocul">
								<li className="py-1 balcksmall">
									{" "}
									St1 - Haatso Town, Kwabenya, Accra East
								</li>
							

								<li className="py-1 balcksmall">

										+233 302 448096 | 233 59 424 4780.

								</li>

								<li className="py-1 balcksmall">

									GE-327-5308. | GhanaPost GE-327-5309.

								</li>

								<li className="py-1 ">
									{" "}
									<a href="#section" className="balcksmall">
										support@bezomoney.com
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="row mb-4">
						<div className="col-12">
							<hr />
						</div>
						<div className="col-12 col-md-6">
							<small>
								Bezo Susu Entreprise is a member of the Ghana
								Co-operative Susu Collectors Association (GCSCA)
							</small>
						</div>

						<div className="col-12 col-md-6 text-md-end">
							<small>© {year} BEZOSUSU. All rights reserved.</small>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
