import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { toast } from "react-toastify";

import reportWebVitals from "./config/reportWebVitals";

import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/index.scss";
import "./assets/css/styles.css";

import { App } from "./app";

toast.configure();

const queryClient = new QueryClient();

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</React.StrictMode>,

	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
